import { useSearchParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { setAdChannelCode, checkSpecialChannel } from '@/store/adChannelCode'
import type { AppDispatch, RootState } from '@/store'
import indexApi from '@/apis/index'
import { getChannelCodeFromStorage, retry, saveRouteCodeMap } from '@/utils/utils'

export const useInitializeAdChannelCode = () => {
  const [search] = useSearchParams()
  const dispatch = useDispatch<AppDispatch>()
  const { adChannelCode } = useSelector((state: RootState) => state.channel)
  const navigator = useNavigate()
  const adRouteCode = search.get('adRouteCode')

  const initAdChannelCode = async() => {
    let newAdChannelCode = ''
    if (adRouteCode && !search.get('adChannelCode')) {
      newAdChannelCode = getChannelCodeFromStorage(adRouteCode)
      if (!newAdChannelCode) {
        const code = await retry(() => indexApi.getAdChannelCodeByRouteCode(adRouteCode))
        newAdChannelCode = code
        saveRouteCodeMap(adRouteCode, newAdChannelCode)
      }
      search.set('adChannelCode', newAdChannelCode)
      search.delete('adRouteCode')
      navigator(`?${search.toString()}`)
    } else {
      newAdChannelCode = search.get('adChannelCode') || search.get('source') || localStorage.getItem('ad_channel_code') || ''
    }
    localStorage.setItem('ad_channel_code', newAdChannelCode)
    dispatch(setAdChannelCode(newAdChannelCode))
    dispatch(checkSpecialChannel())
  }

  useEffect(() => {
    initAdChannelCode()
  }, [dispatch, search, navigator])
}