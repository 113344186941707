/* eslint-disable no-shadow */
// 活动页相关接口
import { api } from '@/utils/axios'
import { EntranceType } from '@/types/apis/index'
import { ApiResponse } from '@/types/apis'
import { CoverStatus, UserInfoStatus } from '@/constants/user'

/** 登录参数 */
interface LoginParams{

  /** 手机号码 */
  mobile?: string, //
  /** 短信验证码 */
  code?: string, //
  /** h5 广告渠道编号 */
  adChannelCode?: string //
  /** 皮肤类型，1-皮肤1、2-皮肤2、皮肤3（非必填） */
  abTestType?: number, //
  /** 是否填写表单类型 */
  isFormReq?: boolean, //
  /** OPPO联登 */
  upStreamUnionLoginReqId?: string
}

/** 登录用户信息 */
export interface LoginUser {

    /** uid */
    uid: string, //
    /** 手机号码 */
    mobile: string, //
    /** 用户资料状态，0-未填写、1-已填写未审核通过、2-已完成 */
    userInfoStatus: UserInfoStatus, //
    /** 是否允许表单覆盖，0-不允许覆盖，1-允许覆盖 */
    coverStatus: CoverStatus //
    /** 用户注册渠道id */
    adChannelId: number //
    /** 微信小程序用户唯一标识 */
    wechatMiniOpenid:string //
}

//
export interface LoginUserResponse{

  /**用户信息 */
  loginUser: LoginUser, //
  /** 第一次登录APP而且没有填写完成表单 */
  firstLogin: number, //
  /** 用于IOS马甲包-有钱助手 */
  JumpUrl:string //
  /** 登录标识 */
  token: string
}
interface GetAppDownloadUrlResponse{
  'url-prod': string,
  'url-qa': string,
  'ios': string
}

interface GetNewestVersionParams{
  appType: number, // APP类型，1-元信花yuanxinwallet.com、2-有钱来ningkun8.cn
}

interface GetNewestVersionResponse{
  androidPackageLocation: string, //安卓包链接
  iosPackageLocation: string //iOS链接
}
interface GetAdChannelParams{
  adChannelCode: string
}

interface GetAdChannelResponse{
  linkType: number, //关联链接类型，1-注册-下载，2-注册-万件-下载
  channelStatus: number //广告渠道状态，1-正常，0-禁用
}

interface ReportAdChannelClickParams{
  adChannelCode: string, // 广告渠道编码
  fingerprint: string, // 浏览器指纹标识
  abTestType: number, // 1-皮肤1、2-皮肤2、皮肤3
}

export interface ReportProductExposureParams{
  productId: number, // 产品id
  entranceType: EntranceType, // 入口类型，1-首页中插，2-首页弹窗、3-开屏、4-顶部推荐、5-首页列表、6-产品列表、7-随机推荐
  appH5?: number // H5标记
}

// 获取IP地址参数
export interface BaiduMapIPGetLocation {
  location?: string
  adChannelCode?: string
  coordtype?:string
}
// 获取参数响应
export interface BaiduMapIPGetLocationResponse {
  city: string | null,
  excludeCity: boolean,
  province:string | null
}

/** H5配置项 */
export interface H5ConfigParams {
  bottomTextStatus?: number;
  bottomText?: string;
}

/** 渠道详情*/
export interface ChannelDetailResponse {
  adChannelCode: string, // 渠道编码
  bumpBaseFlag: number //是否撞库
  channelName: string//渠道名称
  channelStatus: number //渠道状态，1-正常，0-禁用
  createdAt: number //创建时间
  creator: number //创建人id
  creatorName: string | null //创建人名称
  domainType: number
  excludeCityCodes: string[] //排除城市编码
  id: number //渠道id
  linkType: number
  location: number
  pageSetup: number
  unionLoginFlag: number
  trackType: number | null
  isEnableH5Config: number //是否开启H5自定义配置
  H5ConfigItem?: H5ConfigParams
}

/** 发送登录注册手机验证码（不鉴权） */
export interface SmsSendMsg {

  /** 手机号码 */
  mobile: string | number,

  /** 签名 */
  signName?: string,

  /** 渠道码 */
  channelCode?:string
}

const activityApi = {
  // 发送验证码
  smsSend(body:SmsSendMsg) {
    return api.post('/api/login/sendPhoneVerifyCode', body, {
      // mock: true,
    })
  },

  // crm发送验证码
  crmSmsSend(body:SmsSendMsg) {
    return api.post('/api/crm/admin/login/sendPhoneVerifyCode', body, {
      // mock: true,
    })
  },

  // 注册
  login(body:LoginParams) {
    return api.post<LoginUserResponse>('/api/login', body, {
      // mock: true,
    })
  },

  // 获取app下载url
  getAppDownloadUrl() {
    return api.get<GetAppDownloadUrlResponse>(`http://yuanxin-app.oss-cn-shenzhen.aliyuncs.com/app-download.json?t=${Date.now()}`, null, {
      mock: false,
      withoutCheck: true
    })
  },

  // 获取最新版本信息（不鉴权）
  getNewestVersion(body: GetNewestVersionParams) {
    return api.get<GetNewestVersionResponse>('/api/appVersion/newestVersion', body, {
      // mock: true,
      showError: false
    })
  },

  // 获取广告渠道信息（不鉴权）
  getAdChannel(body:GetAdChannelParams) {
    return api.get<GetAdChannelResponse | null>('/api/getAdChannel', body, {
      // mock: true,
    })
  },

  // 上报广告渠道点击浏览（不鉴权）
  reportAdChannelClick(body:ReportAdChannelClickParams) {
    return api.get('/api/reportAdChannelClick', body, {
      // mock: true,
    })
  },

  // 上报用户下载APP
  reportUserDownloadApp(params?:any) {
    return api.get('/api/reportUserDownloadApp', params, {
      // mock: true,
      showError: false,
      withoutCheck: true
    })
  },

  // 用户在线心跳
  health() {
    return api.get('/api/user/health', null, {
      // mock: true,
      withoutCheck: true
    })
  },

  // 上报产品曝光
  reportProductExposure(body:ReportProductExposureParams) {
    return api.get('/api/reportProductExposure', body, {
      // mock: true,
      isCancelRequest: false
    })
  },

  // 根据坐标解析城市信息，若没有传坐标则根据IP解析
  reverseGeo(body:BaiduMapIPGetLocation) {
    return api.get<ApiResponse<BaiduMapIPGetLocationResponse>>('/api/third/baiduMap/excludeCity', body, {
      // mock: true,
      withoutCheck: true
    })
  },
  // 上报设备信息
  reportDevice(body:any) {
    return api.post('/api/reportDevice', body, {
      // mock: true,
    })
  },
  // 获取用户来源与城市
  getUserDetailVO() {
    return api.get('/api/userApi/getUserDetailVO', null, {
      // mock: true,
    })
  },
  // 获取渠道码详情（不鉴权）
  getChannelCodeDetail(adChannelCode:string) {
    return api.get<ChannelDetailResponse>(`/api/adChannel/plan/adChannelCode?adChannelCode=${adChannelCode}`, null, {
      // mock: true,
    })
  },

  //获取H5自定义配置
  getH5Config(query: { adChannelId?: number }) {
    return api.get<H5ConfigParams>('api/adChannel/h5Config', query, {
      //mock: true
    })
  }
}
export default activityApi